import React, { useState, useEffect } from "react";
import axios from "axios";

const Events: React.FC = () => {
  const [events, setEvents] = useState<any[]>([]);
  const [showAll, setShowAll] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const API_BASE_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/events`);
        const formatted = response.data.data.map((event: any) => {
          const { id, Title, Description, Date, Location } = event;
          return {
            id,
            Title,
            Description,
            Date,
            Location,
          };
        });
        setEvents(formatted);
        setLoading(false);
      } catch (err) {
        setError("Erreur lors de la récupération des événements.");
        setLoading(false);
      }
    };

    fetchEvents();
  }, [API_BASE_URL]);

  const toggleShowAll = () => setShowAll(!showAll);

  const displayedEvents = showAll ? events : events.slice(0, 3);

  const formatDate = (dateString: string) => {
    if (!dateString) return "Non spécifiée";
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return "Date invalide";
    return date.toLocaleDateString("fr-FR", {
      weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  if (loading) return <p className="text-center text-white">Chargement...</p>;
  if (error) return <p className="text-center text-red-500">{error}</p>;

  return (
    <section id="events" className="py-16 bg-secondary-dark text-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8 font-serif">
          Événements à Venir
        </h2>
        <div className="grid gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {displayedEvents.map((event) => {
            const { id, Title, Description, Date, Location } = event;

            return (
              <div
                key={id}
                className="bg-neutral-light text-primary-dark rounded-lg shadow-soft p-6"
              >
                <h3 className="text-xl font-semibold mb-2">
                  {Title || "Titre non spécifié"}
                </h3>
                <p className="text-sm mb-4">
                  <span className="font-bold">Date :</span>{" "}
                  {formatDate(Date)}
                </p>
                <p className="text-sm mb-4">
                  <span className="font-bold">Lieu :</span>{" "}
                  {Location || "Non spécifié"}
                </p>
                <p className="text-sm">
                  {Description || "Pas de description disponible"}
                </p>
              </div>
            );
          })}
        </div>

        {events.length > 3 && (
          <div className="text-center mt-8">
            <button
              onClick={toggleShowAll}
              className="px-6 py-2 bg-accent-dark text-white rounded-full hover:bg-accent-light transition"
            >
              {showAll ? "Voir moins" : "Voir plus"}
            </button>
          </div>
        )}
      </div>
    </section>
  );
};

export default Events;
