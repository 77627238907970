import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import ProductCard from "./ProductCard";
import axios from "axios";

interface Product {
  id: number;
  name: string;
  image: string | null;
  description: string;
  price: number;
}

const ProductCarousel: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);

  const API_BASE_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/products?populate=*`);
        const fetchedProducts = response.data.data.map((product: any) => {
          const imageUrl = product.Image?.[0]?.url
            ? `${API_BASE_URL}${product.Image[0].url}`
            : "/images/default.jpg";

          return {
            id: product.id,
            name: product.Name || "Produit",
            description: product.Description || "",
            price: product.Price || 0,
            image: imageUrl,
          };
        });

        setProducts(fetchedProducts);
        setLoading(false);
      } catch (err) {
        setError("Erreur lors de la récupération des produits.");
        setLoading(false);
      }
    };

    fetchProducts();
  }, [API_BASE_URL]);

  if (loading) return <p>Chargement des produits...</p>;
  if (error) return <p className="text-red-500">{error}</p>;

  return (
    <div className="relative px-4 sm:px-6">
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={20}
        slidesPerView={1}
        navigation={{
          nextEl: ".custom-next",
          prevEl: ".custom-prev",
        }}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        loop={true}
        breakpoints={{
          640: { slidesPerView: 1 },
          768: { slidesPerView: 2 },
          1024: { slidesPerView: 3 },
        }}
      >
        {products.map((product) => (
          <SwiperSlide key={product.id}>
            <div
              onClick={() => setSelectedProduct(product)}
              className="cursor-pointer"
            >
              <ProductCard
                name={product.name}
                image={product.image || "/images/default.jpg"}
                description={product.description}
                price={`${product.price} €`}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Navigation personnalisée */}
      <div className="custom-prev bg-accent-dark hover:bg-accent-light text-white p-2 rounded-full absolute top-1/2 left-4 transform -translate-y-1/2 cursor-pointer z-10">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
          viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
        </svg>
      </div>
      <div className="custom-next bg-accent-dark hover:bg-accent-light text-white p-2 rounded-full absolute top-1/2 right-4 transform -translate-y-1/2 cursor-pointer z-10">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
          viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
        </svg>
      </div>

      {/* Modal responsive */}
      {selectedProduct && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 px-4">
          <div className="bg-white rounded-lg p-4 relative w-full max-w-md max-h-[90vh] overflow-auto shadow-lg">
            <button
              className="absolute top-2 right-2 text-red-500 hover:text-red-700"
              onClick={() => setSelectedProduct(null)}
            >
              ✕
            </button>
            <img
              src={selectedProduct.image || "/images/default.jpg"}
              alt={selectedProduct.name}
              className="w-full h-64 object-contain rounded-md mb-4 bg-white"
            />

            <h2 className="text-xl font-bold mb-2">{selectedProduct.name}</h2>
            <p className="text-sm text-gray-700 mb-2">{selectedProduct.description}</p>
            <p className="text-lg font-bold text-accent-dark">{selectedProduct.price} €</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductCarousel;
