import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ProductsFromStrapi from "../components/ProductsFromStrapi";

interface Product {
  id: number;
  Name: string;
  Description: string;
  Price: number;
  Type: string;
  Image: { url: string }[];
}

const ProductsPage: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState(""); // Barre de recherche
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc"); // Tri
  const [selectedType, setSelectedType] = useState<string>(""); // Filtre par type
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null); // Produit sélectionné pour la modal

  const filterProducts = (products: Product[]) => {
    return products
      .filter((product) =>
        product.Name.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .filter((product) =>
        selectedType ? product.Type === selectedType : true
      )
      .sort((a, b) =>
        sortOrder === "asc" ? a.Price - b.Price : b.Price - a.Price
      );
  };

  return (
    <div className="font-sans bg-secondary-light min-h-screen flex flex-col">
      <Navbar />
      <main className="flex-grow pt-24 sm:pt-16">
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-3xl font-bold text-center mb-8 text-white">
            Nos Produits
          </h1>

          {/* Filtres */}
          <div className="p-4 rounded-lg shadow-md mb-8 bg-primary-dark">
            <div className="flex flex-wrap justify-center gap-4">
              {/* Barre de recherche */}
              <div className="relative w-full md:w-1/3">
              <input
                type="text"
                placeholder="Rechercher un produit..."
                  className="w-full p-2 pl-10 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-accent-light focus:border-transparent transition duration-300"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
                <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-neutral-medium">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M8 16l-4-4m0 0l4-4m-4 4h16"
                    />
                  </svg>
                </span>
              </div>

              {/* Tri par prix */}
              <select
                value={sortOrder}
                onChange={(e) =>
                  setSortOrder(e.target.value as "asc" | "desc")
                }
                className="p-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-accent-light focus:border-transparent transition duration-300"
              >
                <option value="asc">Prix : Croissant</option>
                <option value="desc">Prix : Décroissant</option>
              </select>

              {/* Filtre par type */}
              <select
                value={selectedType}
                onChange={(e) => setSelectedType(e.target.value)}
                className="p-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-accent-light focus:border-transparent transition duration-300"
              >
                <option value="">Tous les types</option>
                <option value="Collier">Collier</option>
                <option value="Bracelet">Bracelet</option>
                <option value="Boucles">Boucles d'Oreilles</option>
                <option value="Bague">Bague</option>
              </select>

              {/* Réinitialiser les filtres */}
              <button
                onClick={() => {
                  setSearchTerm("");
                  setSortOrder("asc");
                  setSelectedType("");
                }}
                className="p-2 bg-accent-dark text-white rounded-md shadow-md hover:bg-accent-light transition duration-300"
              >
                Réinitialiser
              </button>
            </div>
          </div>

          {/* Liste des produits */}
          <ProductsFromStrapi
            filterProducts={filterProducts}
            onProductClick={setSelectedProduct} // Passer la fonction pour gérer les clics
          />
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default ProductsPage;
