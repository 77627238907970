import React, { useEffect, useState } from "react";
import axios from "axios";

interface Event {
  id: number;
  Title: string;
  Date: string;
}

const EventBanner: React.FC = () => {
  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const API_BASE_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/events`);
        const fetchedEvents = response.data.data.map((event: any) => ({
          id: event.id,
          Title: event.Title || "",
          Date: event.Date || "",
        }));        
        setEvents(fetchedEvents);
        setLoading(false);
      } catch (err) {
        setError("Erreur lors de la récupération des événements.");
        setLoading(false);
      }
    };

    fetchEvents();
  }, [API_BASE_URL]);

  const formatEuropeanDate = (dateString: string) => {
    if (!dateString) return "Date inconnue";
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return "Date invalide";
    return date.toLocaleDateString("fr-FR", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  if (loading) return null;
  if (error) return <p className="text-center text-red-500">{error}</p>;

  return (
    <div className="bg-accent-dark text-white py-2">
      <div className="container mx-auto px-4 overflow-hidden">
        <div className="flex gap-8 animate-marquee whitespace-nowrap">
          {events.map((event) => (
            <div key={event.id} className="flex-shrink-0">
              <span className="font-semibold">{event.Title}</span> -{" "}
              <span className="text-sm">{formatEuropeanDate(event.Date)}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EventBanner;
