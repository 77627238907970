import React from "react";
import aboutImage from '../../images/about.jpg'; // depuis `src/assets`

const About: React.FC = () => {
  return (
    <section id="about" className="py-16 bg-secondary-dark text-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8 font-serif">À Propos de Nous</h2>
        <div className="flex flex-col md:flex-row items-center md:space-x-8">
          {/* Image */}
          <div className="w-full md:w-1/2 flex justify-center">
            <img
              src={aboutImage}
              alt="Bijoux Artisanaux"
              className="rounded-lg shadow-soft max-h-[400px] w-auto object-cover"
            />
          </div>
          {/* Texte */}
          <div className="w-full md:w-1/2 mt-8 md:mt-0 text-center md:text-left">
            <p className="text-neutral-light text-lg leading-relaxed">
              Chez <span className="font-semibold text-primary-dark">Bijoux de Kiltejade</span>,
              nous sommes passionnés par la création de bijoux uniques et artisanaux. Chaque
              pièce est réalisée avec soin pour refléter l’élégance et le caractère de
              ceux qui les portent. Nos créations s’inspirent des traditions et intègrent des
              matériaux de qualité pour garantir des bijoux intemporels.
            </p>
            <p className="text-neutral-light text-lg leading-relaxed mt-4">
              Depuis notre création, nous avons participé à de nombreux événements pour
              partager notre passion et rencontrer nos clients. Découvrez nos collections et
              laissez-vous séduire par des bijoux qui racontent une histoire.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
