import React from "react";
import { FaFacebookF, FaInstagram, FaTiktok } from "react-icons/fa";
const ContactInfo: React.FC = () => {
  return (
    <section id="contact" className="py-16 bg-secondary-light bg-opacity-60 text-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8 font-serif">Contactez-Nous</h2>
        
        <div className="flex flex-col md:flex-row justify-center items-stretch gap-8 max-w-4xl mx-auto">
          {/* Infos de contact */}
          <div className="flex-1 bg-neutral-light rounded-lg shadow-soft p-6 text-primary-dark text-center space-y-4">
            <p className="text-xl font-semibold">Les Bijoux de Kiltejade</p>
            <p>📍 Saint-Étienne</p>
            <p>📞 <a href="tel:0647937912" className="hover:underline">06 47 93 79 12</a></p>
            <p>✉️ <a href="mailto:kiltejad@hotmail.fr" className="hover:underline">kiltejad@hotmail.fr</a></p>
            <p>SIRET : 530 407 865 00019</p>
          </div>
          </div>
      </div>
    </section>
  );
};

export default ContactInfo;
