import React from "react";

const HeroSection: React.FC = () => {
  return (
    <section
      className="relative bg-cover bg-center h-screen"
      style={{ backgroundImage: "url('../../../bg.jpg')" }}
    >
      <div className="absolute inset-0 bg-primary-dark bg-opacity-60 flex flex-col items-center justify-center text-white">
        <h1 className="text-4xl md:text-6xl font-bold mb-4 text-center font-serif">
          Découvrez la Beauté Unique de Nos Bijoux
        </h1>
        <p className="text-lg md:text-2xl text-center">
          Des créations artisanales pour des moments inoubliables.
        </p>
        <a
          href="#about"
          className="mt-8 bg-accent text-white font-bold py-3 px-6 rounded hover:bg-accent-light transition duration-300"
        >
          En savoir plus
        </a>
      </div>
    </section>
  );
};

export default HeroSection;
