import React from "react";

const Footer: React.FC = () => {
  return (
    <footer className="bg-primary-dark text-white py-8">
      <div className="container mx-auto px-4 text-center">
        <p className="text-sm">
          © {new Date().getFullYear()} Bijoux Artisanaux - Tous droits réservés.
        </p>
        <div className="flex justify-center space-x-4 mt-4">
          <a
            href="#"
            className="flex items-center text-white hover:text-accent-infos transition-colors duration-300"
          >
            <i className="fab fa-facebook mr-2"></i> Facebook
          </a>
          <a
            href="https://www.instagram.com/les_bijoux_de_kiltejade?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
            className="flex items-center text-white hover:text-accent-infos transition-colors duration-300"
          >
            <i className="fab fa-instagram mr-2"></i> Instagram
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
