import React from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import HeroSection from "../components/Home/HeroSection";
import About from "../components/About/About";
import ContactForm from "../components/Contact/ContactForm";
import Footer from "../components/Footer";
import ProductCarousel from "../components/Products/ProductCarousel";
import Events from "../components/Events/Events";
import EventBanner from "../components/Events/EventBanner";
import { events } from "../data/events";

const Home: React.FC = () => {
  const navigate = useNavigate(); // Hook pour naviguer

  return (
    <div className="font-sans">
      {/* Navbar */}
      <Navbar />

      <div style={{ paddingTop: "60px" }}>
        <EventBanner />
      </div>

      {/* Hero Section */}
      <HeroSection />

      {/* About Section */}
      <About />

      {/* Products Section */}
      <section id="products" className="py-16 bg-secondary-light bg-opacity-60">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center text-white mb-8 font-serif">
            Nos Bijoux
          </h2>
          <ProductCarousel />

          {/* Bouton vers la page des produits */}
          <div className="text-center mt-8">
            <button
              onClick={() => navigate("/products")} // Redirection vers /products
              className="px-6 py-3 bg-accent-dark text-white rounded-md shadow-md hover:bg-accent-light transition"
            >
              Voir Tous les Produits
            </button>
          </div>
        </div>
      </section>

      {/* Events Section */}
      <Events />

      {/* Contact Form */}
      <ContactForm />

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Home;
