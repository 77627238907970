import React from "react";

interface ProductCardProps {
  name: string;
  image: string;
  description: string;
  price: string;
}

const ProductCard: React.FC<ProductCardProps> = ({ name, image, description, price }) => {
  return (
    <div className="bg-neutral-light text-primary-dark rounded-lg shadow-soft overflow-hidden transition-transform duration-300 hover:scale-105">
      {/* Image du produit */}
      <img
        src={image}
        alt={name}
        className="w-full h-64 object-contain rounded-md bg-white"      />

      {/* Informations du produit */}
      <div className="p-4">
        <h3 className="text-xl font-semibold">{name}</h3>
        <p className="text-neutral-dark mt-2">{description}</p>
        <p className="mt-4 text-lg font-bold text-accent-dark">{price}</p>
      </div>
    </div>
  );
};

export default ProductCard;
