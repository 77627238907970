import React, { useEffect, useState } from "react";
import axios from "axios";

interface Image {
  url: string;
}

interface Product {
  id: number;
  Name: string;
  Description: string;
  Price: number;
  Type: string;
  Image: Image[];
}

interface ProductsFromStrapiProps {
  filterProducts: (products: Product[]) => Product[];
  onProductClick: (product: Product) => void;
}

const ProductsFromStrapi: React.FC<ProductsFromStrapiProps> = ({
  filterProducts,
}) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);

  const API_BASE_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/products?populate=*`
        );
        setProducts(response.data.data || []);
        setLoading(false);
      } catch (err) {
        setError(
          "Une erreur est survenue lors de la récupération des produits."
        );
        setLoading(false);
      }
    };

    fetchProducts();
  }, [API_BASE_URL]);

  if (loading) return <p>Chargement...</p>;
  if (error) return <p>{error}</p>;

  const filteredProducts = filterProducts(products);

  if (filteredProducts.length === 0) {
    return (
      <p className="text-center text-neutral-dark mt-8">
        Aucun produit ne correspond à vos critères.
      </p>
    );
  }

  return (
    <div>
      <div className="grid gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {filteredProducts.map((product) => {
          const imageUrl = product.Image?.[0]?.url
            ? `${API_BASE_URL}${product.Image[0].url}`
            : "/images/default.jpg";

          return (
            <div
              key={product.id}
              className="bg-white rounded-lg shadow-soft p-6 text-primary-dark cursor-pointer"
              onClick={() => setSelectedProduct(product)}
            >
              <img
                src={imageUrl}
                alt={product.Name}
                className="w-full h-48 object-contain rounded-md mb-4 bg-white"
              />
              <h3 className="text-xl font-semibold">{product.Name}</h3>
              <p className="text-sm mb-2">{product.Description}</p>
              <p className="text-lg font-bold text-accent-dark">
                {product.Price} €
              </p>
            </div>
          );
        })}
      </div>

      {selectedProduct && (
        <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50">
          <div className="bg-white w-3/4 max-w-4xl rounded-lg p-6 shadow-lg relative">
            <button
              className="absolute top-4 right-4 text-red-500 hover:text-red-700 text-xl"
              onClick={() => setSelectedProduct(null)}
            >
              ✕
            </button>
            <div className="flex flex-col md:flex-row gap-4">
              <img
                src={
                  selectedProduct.Image?.[0]?.url
                    ? `${API_BASE_URL}${selectedProduct.Image[0].url}`
                    : "/images/default.jpg"
                }
                alt={selectedProduct.Name}
                className="w-full md:w-1/2 max-h-80 object-contain rounded-md bg-white"
              />

              <div className="flex-1">
                <h2 className="text-2xl font-bold mb-4 text-primary-dark">
                  {selectedProduct.Name}
                </h2>
                <p className="text-lg text-gray-700 mb-4">
                  {selectedProduct.Description}
                </p>
                <p className="text-xl font-bold text-accent-dark mb-6">
                  {selectedProduct.Price} €
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductsFromStrapi;
