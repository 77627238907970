import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FiMenu, FiX } from "react-icons/fi";
import { scrollToId } from "../utils/scrollTo"; // adapte le chemin si besoin

const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const closeMenu = () => setIsOpen(false);

  const handleScroll = (id: string) => {
    if (location.pathname !== "/") {
      navigate("/");
      // attendre le DOM après navigation
      setTimeout(() => scrollToId(id), 100);
    } else {
      scrollToId(id);
    }
    setIsOpen(false);
  };

  return (
    <nav className="bg-primary-dark text-white p-4 shadow-md fixed w-full top-0 z-50">
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo */}
        <Link
          to="/"
          onClick={closeMenu}
          className="text-xl font-bold hover:text-accent-infos transition duration-300"
        >
          Les Bijoux de Kiltejade
        </Link>

        {/* Burger Icon (mobile) */}
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="md:hidden text-2xl focus:outline-none"
        >
          {isOpen ? <FiX /> : <FiMenu />}
        </button>

        {/* Navigation */}
        <ul
          className={`md:flex gap-6 md:items-center absolute md:static bg-primary-dark w-full left-0 md:w-auto px-4 md:px-0 transition-all duration-300 ease-in-out ${
            isOpen ? "top-16 opacity-100" : "top-[-500px] opacity-0"
          } md:opacity-100 md:top-0`}
        >
          <li>
            <button
              onClick={() => handleScroll("about")}
              className="block py-2 md:py-0 hover:text-accent-infos transition duration-300"
            >
              À propos
            </button>
          </li>
          <li>
            <button
              onClick={() => handleScroll("products")}
              className="block py-2 md:py-0 hover:text-accent-infos transition duration-300"
            >
              Bijoux
            </button>
          </li>
          <li>
            <button
              onClick={() => handleScroll("events")}
              className="block py-2 md:py-0 hover:text-accent-infos transition duration-300"
            >
              Événements
            </button>
          </li>
          <li>
            <button
              onClick={() => handleScroll("contact")}
              className="block py-2 md:py-0 hover:text-accent-infos transition duration-300"
            >
              Contact
            </button>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
